import DirectorCard from './directorcard';
import { MDBCol } from 'mdbreact';
import React from 'react';

const DirectorsList = (props) => {

    const directors = props.directors

    let numRows
    (directors != null) ? numRows = directors.length : numRows = 4
    let xsVl; let mdVl; let lgVl
  
    if (numRows === 1) {
        xsVl = `12`; mdVl = `12`; lgVl = `12`
    }   
    else {
        if (numRows === 2) {
            xsVl = `12`; mdVl = `6`; lgVl = `6`
        } 
        else {
            if (numRows % 3 === 0) {
                xsVl = `12`; mdVl = `4`; lgVl = `4`
            }
            else {
                xsVl = `12`; mdVl = `6`; lgVl = `3`
            }
        }
    }

    return (
        <>
            {directors.relationships.field_director.map((director, key) => {  
            let imageDirector = '/images/avatars/noimage.png'
            if (director.relationships.field_member_photo) { 
                imageDirector = director.relationships.field_member_photo.localFile.childImageSharp.director_photo.src
            }
            return  (
                <MDBCol key={key} xs={xsVl} md={mdVl} lg={lgVl} className={`col-${xsVl} px-1 py-0 m-0`}>
                    <DirectorCard
                        image={imageDirector}
                        name={director.field_title}
                        alias={director.field_sortalias}
                        role={director.field_sub_title}
                    />
                </MDBCol>
                )
            })}
        </>
    )
}

export default DirectorsList;